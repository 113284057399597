import React, { useContext, useState,useRef,useEffect} from 'react'
import Context from '../context'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'

const ProductCard=(props)=>{
const cardId=props.card
const { stateUser, dispatchUser } = useContext(Context)
const { stateProduct, dispatchProduct } = useContext(Context)
const {activeDate,props_Code,  props_User}=stateUser
const {data}=stateProduct
const colorChange = useRef();
    const [howmany,setHowmany]=useState(0)
   // console.log('ref values algus',box.current.color);
    //box.current.sx.backgroundColor = "blue";
     const amount=(hm)=>{
      const tulem=howmany+hm
      setHowmany(tulem)
     return  tulem
    }
    function addFirstProduct(e) {
     
//box.current.div.backgroundColor('green')
//e.current.target.backgroundColor('red')
//  {backgroundColor:"#c5f599"}  
      dispatchProduct({ type: 'ADD_FIRST_PRODUCT', payload:amount(1) ,productName:cardId})
    }
function changePosProduct() {
 
 // box.current.color='text.primary'
  dispatchProduct({ type: 'CHANGE_AMOUNTPOS', payload:amount(1) ,productName:cardId})
}

    const hndPlus = (e) => {
     e.preventDefault();
     
     console.log('ref values peale clicki', colorChange.current);
      
     

        ( stateProduct.data.length===0  && howmany<2)? 
           addFirstProduct()  : changePosProduct()
          
           colorChange.current.style.backgroundColor='#b1fdebde'
        
         
        
      
      }
      const hndMinus = (e) => {
      e.preventDefault();
         if( howmany>0) {
          
          dispatchProduct({ type: 'CHANGE_AMOUNTNEG', payload:amount(-1) ,productName:cardId})}
       
          
        
      
      }
    
    return (
<Grid item xs={12} sm={6} md={4}>
      <Card ref={colorChange} style={{backgroundColor: "#f1f1f1"}}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          opacity: [1, 1, 1],
          
        }}
      >
        <CardContent key={`hm${cardId}`}>
<Typography  sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
Valitud {howmany}
</Typography>
        
</CardContent>
        <CardMedia
          key={`cm${cardId}`}
          component='img'
          sx={{
            // 16:9
            pt: '1rem'
          }}
          image={`./images/${cardId}.jpg`}
          alt='Toodang'
        />
        <CardActionArea>
          <CardActions>
            <Button key={`btpluss${cardId}`}  size='small'  onClick={hndPlus}>
              <AddCircleOutlineRoundedIcon />
            </Button>
            <Button  key={`btminus${cardId}`} size='small' onClick={hndMinus}>
              <RemoveCircleOutlineRoundedIcon />
            </Button>
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
 



)
        }   
        export default ProductCard