import React, { useState, useContext } from 'react'

import Context from '../context'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import RegisterForm from './RegisterForm'
import Order from './Order'
import Summary from './Summary'
import MonthList from './MonthList'
import Alert from './Alert'
import { fabClasses } from '@mui/material'
//import { useNavigate } from "react-router-dom";
//const steps = ['Registreeri', 'Osta', 'Ülevaade', 'Kuu ostud']
const steps = ['Registreeri', 'Osta', 'Kinnita','Kuu ostud']
const theme = createTheme()

const Main = () => {
  const { stateUser, dispatchUser } = useContext(Context)
  const { stateProduct, dispatchProduct } = useContext(Context)
  const [formValues, setFormValues] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  //let navigate = useNavigate();
  
  console.log('main state USER', stateUser, 'mainstate DATA:',stateProduct)
 
 
  function getStepContent (step) {

    switch (step) {
      case 0:
        return <RegisterForm {...formValues} />
      case 1:
       
        return <Order {...formValues} />
      case 2:
        return <Summary {...formValues} />
       case 3:
         return <MonthList {...formValues} />
         
      default:
        throw new Error('Viga')
    }
  }
  const handleNext = newValues => {
    if (stateUser.props_User.length==0) {
      setShowAlert(true)
    //  window.alert('Nime sisestamine kohustuslik!')
setActiveStep(0)

    } else {
      setShowAlert(false)
    setFormValues({ ...formValues, ...newValues })
    activeStep===2?setActiveStep(activeStep +2):setActiveStep(activeStep + 1)}
  }

  const handleBack = newValues => {
    setActiveStep(activeStep - 1)
    setFormValues({ ...formValues, ...newValues })
  }
  const handleEnd = () => {
    dispatchUser({ type: 'NEW_EMPTY' })
    dispatchProduct({ type: 'NEW_EMPTY_PRODUCT' })


    setActiveStep(0)
  }

  const handleMonthList = (newValues) => {
   // e.preventDefault();
   setActiveStep(activeStep+1 )
    setFormValues({ ...formValues, ...newValues })
   
   
   //navigate('/monthList')
    
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
        <Paper
          variant='elevation' elevation={3}
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component='h1' variant='h4' align='center'>
            {`Tänane ost -- ${stateUser.activeDate}--${stateUser.props_User}`}
          </Typography>
          { showAlert && <Alert textContent={'Nime sisestamine kohustuslik'} /> }
          
          <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 2 }}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
         
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant='h5' gutterBottom>
                Täname ostu eest
              </Typography>
              <Button
                variant='contained'
                onClick={handleEnd}
                sx={{
                  mt: 5,
                  ml: '80%'
                }}
              >
                Algus
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Tagasi
                  </Button>
                )}
{activeStep === 2 && (
                  <Button onClick={handleMonthList} sx={{ mt: 3, ml: 1 }}>
                   Kuuostud
                  </Button>
                )}
                <Button
                  variant='contained'
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 2 ? 'Kinnita' : 'Edasi'}
                </Button>
              </Box>
            </React.Fragment>
          )}
          <h6>{`ver ${process.env.REACT_APP_VERSION}`}</h6>
        </Paper>
      </Container>
    </ThemeProvider>
  )
}
export default Main
