import React, { Fragment,useReducer} from 'react'
//import {useImmerReducer} from 'use-immer';
import { BrowserRouter, Routes, Route} from 'react-router-dom'

import reducer, { initialstateUser,initialstateProduct } from './reducer'
import Context from './context'

import Main from './components/Main'
import MonthList from './components/MonthList'
import Order from './components/Order'
import Summary from './components/Summary'

const App = () => {
  const [stateUser, dispatchUser] = useReducer(reducer, initialstateUser)
  const [stateProduct, dispatchProduct] = useReducer(reducer, initialstateProduct)
  console.log('stateUser in App', stateUser,'stateProduct in App', stateProduct)

  return (
    <Context.Provider value={{ stateUser, dispatchUser,stateProduct,dispatchProduct }}>
      <Fragment>
        <BrowserRouter>
          <Routes>
          <Route path='*' element={<Main />} />
            <Route path='/monthList' element={<MonthList />} />
            <Route path='/order' element={<Order />} />
            <Route path='/summary' element={<Summary />} />
           
          </Routes>
        </BrowserRouter>
      </Fragment>
    </Context.Provider>
  )
}

export default App
//;<Main />
