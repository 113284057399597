import React, { useContext ,useState} from 'react'
import * as Realm from "realm-web";
import Context from '../context'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Button } from '@mui/material'
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import Alert from './Alert'

const RegisterForm =()=> {
  
  const { stateUser, dispatchUser } = useContext(Context)
  const [showAlert, setShowAlert] = useState(false)
  const app = new Realm.App({ id:'sai-0-wroip' });
  const mongo = app.currentUser.mongoClient('mongodb-atlas');
  const users = mongo.db('sai').collection('users');


  const handleUserName = e => {
    dispatchUser({ type: 'CHANGE_USER', payload: e.target.value })
    
  }
  


  const handleUserCode =async  e => {
    
    const findOk =  await users.findOne({ "id":e.target.value });

if( findOk!=null ) {
  console.log('handleusercode findOk',findOk.id);
  setShowAlert(false)
  dispatchUser({ type: 'CHANGE_CODE', payload: findOk.id })
  dispatchUser({ type: 'CHANGE_USER', payload:findOk.UserName })
} else {
  setShowAlert(true)
}
  }
  
  const handleClear = e => {
    setShowAlert(false)
    dispatchUser({ type: 'NEW_EMPTY' })
    dispatchUser({ type: 'NEW_EMPTY_PRODUCT' })
  }

  console.log('02 regform props lahti stateUser', stateUser)
  return (
    <React.Fragment>
       { showAlert && <Alert textContent={'Kood puudub andmebaasist'} /> }
      <Grid container spacing={5}>
        <Grid item xs={8} sm={5}>
          <TextField
            required={true}
            key={'kood'}
            id='kood'
            name='kood'
            label='Kood'
            variant='standard'
            value={stateUser.props_Code}
            onChange={handleUserCode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key={'userName'}
            id='userName'
            name='userName'
            label='Nimi'
            variant='standard'
            value={stateUser.props_User}
            onChange={handleUserName}
          />
         
        </Grid>
        <Grid item xs={6} sm={3}>
        <Button key={`clear1`}  size='small'  onClick={handleClear}>
              <ClearTwoToneIcon /> Puhasta
            </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default RegisterForm