import React, { useContext} from 'react'
import Context from '../context'
//import { Link as RouterLink, MemoryRouter } from 'react-router-dom'
//import { StaticRouter } from 'react-router-dom/server'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate} from "react-router-dom";
function ccyFormat (num) {
  return `${num.toFixed(2)}`
}

function priceRow (qty, unit) {
  return qty * unit
}

// function createRow (date, desc, qty, unit) {
//   const price = priceRow(qty, unit)
//   return { date, desc, qty, unit, price }
// }

function subtotal (items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
}

// const rows = [
//   createRow('01.01.23', 'Väike kook', 2, 0.3),
//   createRow('05.01.23', 'Tainas', 2, 0.5),
//   createRow('10.01.23', 'Tasuta kook', 2, 0),
//   createRow('23.10.23', 'Stritsel1', 1, 0.77),
//   createRow('01.01.23', 'Väike kook1', 2, 0.3),
//   createRow('05.01.23', 'Tainas1', 2, 0.5),
//   createRow('10.01.23', 'Tasuta kook1', 2, 0),
//   createRow('23.10.23', 'Stritsel2', 1, 0.77)
// ]
let rows=require('../data/rows.json')
const invoiceSubtotal = subtotal(rows)

const MonthList = () => {
  const { stateUser } = useContext(Context)
  const [open, setOpen] = React.useState(true)
 const [scroll, setScroll] = React.useState('paper')
 let navigate = useNavigate();
 
   
 
 const handleClose = (e) => {
    e.preventDefault();
  
    navigate('/main', { state: { from: '/main' } })
    setOpen(false)
    
  }

  const descriptionElementRef = React.useRef(null)
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus()
  //     }
  //   }
  // }, [open])

  console.log(
    '03 monthlist propsid',
    stateUser.activeDate,
    stateUser.props_Code,
    stateUser.props_User
  )

  return (
    <React.Fragment>
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'> {`${stateUser.props_User} eelnevad kuuostud`}</DialogTitle>
      <DialogContent >
        <DialogContentText
          id='scroll-dialog-description'
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <React.Fragment>
            <TableContainer
              component={Paper}
              
            >
              <Table
                sx={{ minWidth: '30rem', fontSize: '80%' }}
                aria-label='spanning table '
              >
                <TableHead>
                  
                  <TableRow>
                    <TableCell align='left'>Kuupäev</TableCell>
                    <TableCell>Toode</TableCell>
                    <TableCell align='right'>Summa</TableCell>
                                    <TableCell align='right'>Kogus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.date}</TableCell>
                      
                      <TableCell>{row.desc}</TableCell>
                      <TableCell align='right'>
                        {ccyFormat(row.price)}
                      </TableCell>
                      <TableCell align='right'>{row.qty}</TableCell>
                               
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell rowSpan={2}></TableCell>
                    <TableCell align='right' colSpan={1}>
                      Kokku
                    </TableCell>
                    <TableCell align='right'>
                      {ccyFormat(invoiceSubtotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{`Tagasi`}</Button>
      </DialogActions>
    </Dialog>
   
    </React.Fragment>
  )
}
export default MonthList

