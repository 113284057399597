import * as Realm from "realm-web";

const cardCount= () => {  

    const app = new Realm.App({ id:'sai-0-wroip' });
    const mongo = app.currentUser.mongoClient('mongodb-atlas');
    const kaardid = mongo.db('sai').collection('products');
    const arr=[]
    //debugger
   
    
        kaardid.count().then(amountCard=>{
       
 for (var i = 1; i <= amountCard; i++) {
    
            arr.push(i);}
            
       }
       

       )
      .finally(arr)
      
return arr
    
    }  

   
   

 
export default cardCount;
