import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import Container from '@mui/material/Container'

import ProductCard from './ProductCard'
import cardCount from '../data/CardCount'


const  cards=  cardCount()
const Order = () => {
   return (
    <div>
      <CssBaseline />

      <Container maxWidth='sm'>
        <Stack
          sx={{ pt: 4 }}
          direction='row'
          spacing={2}
          justifyContent='center'
        ></Stack>
      </Container>

      <Container sx={{ py: 8 }} maxWidth='md'>
        <Grid container spacing={4}>
        {cards && cards.map(card => (
           <ProductCard key={card} card={card}/>
        ))}
          
        </Grid>
      </Container>
    </div>
  )
}
export default Order


