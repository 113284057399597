//import AuthAnonymous from "./auth/AuthAnonymous"
import * as Realm from "realm-web";
const ostuKuup = () => {
  const nowDate = new Date()
  const formatedDay = `${nowDate.getDate()}/${nowDate.getMonth() +
    1}/${nowDate.getFullYear()}`
  return formatedDay
}
const   AuthAnonymous= async  ()=>{

  const app = new Realm.App({ id:'sai-0-wroip' });
const credentials = Realm.Credentials.anonymous();
try {
  const user = await app.logIn(credentials);
  return user.id
} catch(err) {
  console.error("Failed to log in", err);
}


  //debugger
    //"Producing code" (May take some time)
     //const app =   new Realm.App({ id:'sai-0-wroip' }).then((appr)=>{return appr.currentUser.id})
    
   // console.log('App',appr);
    //resolve()// When successful
 
    //reject(console.log('AuthAnon Viga',reject)) // When failed
    //return currUserId
 }
// const convertArrayToObject = (array, key) => {
//   const initialValue = {};
//   return array.reduce((obj, item) => {
//     return {
//       ...obj,
//       [item[key]]: item,
//     };
//   }, initialValue);
// };
//initialstateUser,initialstateProduct

export const initialstateUser = {
  

  activeDate: ostuKuup(),
  props_AnonUser:  AuthAnonymous(),
  props_Code: '',
  props_User: ''
}
export const initialstateProduct ={ data:[]}

const reducer= (state, action)=> {
   
  
  console.log('reducer action',action);
  switch (action.type) {
    case 'NEW_EMPTY':
      return {
        ...state,
        activeDate: ostuKuup(),
        props_Code: '',
        props_User: ''
        
        
      }
      case 'NEW_EMPTY_PRODUCT':
      return {
        ...state,        
        data:[]
        
      }
    case 'CHANGE_CODE':
      return { ...state, props_Code: action.payload }

    case 'CHANGE_USER':
      return { ...state, props_User: action.payload }

   case 'ADD_FIRST_PRODUCT':

   return {
    ...state,data:[{props_Amount:action.payload,props_CardId:action.productName}]
    
   }

      
         case 'CHANGE_AMOUNTPOS':
          {
           
          const indexOfProduct = state.data.findIndex((item) => item.props_CardId === action.productName)
          let newState = state.data.slice(); // <-- create new array reference
          if( indexOfProduct === -1)
          {
            console.log('lisa uus kirje arraysse');
          return {
            
            ...state,data:[...state.data,{props_Amount:action.payload,props_CardId:action.productName}]
            
          }}
 // Already in cart, increment count by 1
 newState[indexOfProduct] = {
  ...newState[indexOfProduct],
  props_Amount: newState[indexOfProduct].props_Amount + 1
}
let dataMap= newState.map(u => u.props_CardId !== newState[indexOfProduct] ? u :newState[indexOfProduct]  );

return {...state,data:dataMap}

         
            
          }
        case 'CHANGE_AMOUNTNEG':
          const indexOfProduct = state.data.findIndex((item) => item.props_CardId === action.productName)
          let newState = state.data.slice(); // <-- create new array reference
         
 newState[indexOfProduct] = {
  ...newState[indexOfProduct],
  props_Amount: newState[indexOfProduct].props_Amount - 1
}
let dataMap= newState.map(u => u.props_CardId !== newState[indexOfProduct] ? u :newState[indexOfProduct]  );

return {...state,data:dataMap}
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
export default reducer