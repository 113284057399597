import React, { useCallback, useContext, useEffect,useLayoutEffect, useRef } from 'react'
import * as Realm from "realm-web";
import Context from '../context'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button  from '@mui/material/Button';
//import MonthList from './MonthList'
//import { useNavigate } from "react-router-dom";
// const products = [
//   {
//     id:1,
//     nameArt: 'Väike kook',
//     desc: 'Väike kook',
//     price: 0.60
//   },
//   {
//     id:2,
//     nameArt: 'Tainas',
//     desc: 'Ost',
//     price: 1.00
//   },
//   {
//     id:3,
//     nameArt: 'Tasuta kook',
//     desc: 'Tasuta kook',
//     price: 0.00
//   },
//   {
//     id:4,
//     nameArt: 'Stritsel',
//     desc: 'Stritsel',
//     price: 0.77
//   },
//   {
//     id:5,
//     nameArt: 'Stritsel',
//     desc: 'Stritsel',
//     price: 0.77
//   },
//   {
//     id:6,
//     nameArt: 'Stritsel',
//     desc: 'Stritsel',
//     price: 0.77
//   }
// ]
let products=require('../data/products.json')
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}
const app = new Realm.App({ id:'sai-0-wroip' });
const mongo = app.currentUser.mongoClient('mongodb-atlas');
const kaardid = mongo.db('sai').collection('products');
const kaardidOk  =async () => { await  kaardid.find({ })}

const Summary= ()=> {
   const { stateUser } = useContext(Context)
  const { stateProduct, dispatchProduct } = useContext(Context)
   const [basket,setBasket]=useState(0)
   //let navigate = useNavigate();
  
   function takeBasketSum(arg) {
     setBasket(prev=>prev+ arg)
return basket
  }
 
  const subtotal=()=> {
    
    
    stateProduct.data.map((obj)=>{
     
      const fullProduct = products.filter(element => element.id === obj.props_CardId)
       takeBasketSum(Number(fullProduct[0].price)*Number(obj.props_Amount))
      
  
  })
  
}
useEffect(()=>{

 return subtotal()


},[])


 
  return (
   
    <React.Fragment>
       
     
      <TableContainer elevation={3} component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="spanning table">
        <TableHead>
         
          <TableRow>
            <TableCell>Toode</TableCell>
            <TableCell align="right">Kogus.</TableCell>
            <TableCell align="right">Hind</TableCell>
            <TableCell align="right">Kokku</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { 
     stateProduct.data.map((obj,objId)=>{
      const fullProduct = products.filter(element => element.id === obj.props_CardId);
      
      //const [{id,nameArt,desc,price}]=fullProduct
    return  ( 
  <TableRow key={fullProduct[0].id}>
  <TableCell>{fullProduct[0].nameArt}</TableCell>
  <TableCell align="right">{obj.props_Amount} </TableCell>
   <TableCell align="right">{ccyFormat(Number(fullProduct[0].price))}</TableCell>
   <TableCell align="right">{ccyFormat(Number(fullProduct[0].price)*Number(obj.props_Amount))}</TableCell>
</TableRow>
)
      }  )}
     
         

          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Kokku:</TableCell>
            <TableCell align="right">{ccyFormat(basket)}</TableCell>
          </TableRow>
          
         
        </TableBody>
      </Table>
     
    </TableContainer>




      
    </React.Fragment>
  )
}
export default Summary






