import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Alert(props) {

    //const textContent=props
  

  

  return (
    
      <Chip   variant="outlined" color="warning" icon={<DoneIcon />} 

        label={props.textContent}
       
      />
     
  );
}